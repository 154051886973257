export default () => {
  const i18n = useI18n();
  const authStore = useAuthStore();
  const region = computed(() => {
    if (authStore.activeRegion != null) {
      return authStore.activeRegion.toLowerCase();
    }

    return getRegion(i18n.locale.value);
  });

  return { region };
};
